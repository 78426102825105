/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

export default function Addaneditform(props) {
    const { visible, setVisible, formdata, handlechange, handlesave, handleupdate } = props;
    const [pdfName, setPdfName] = useState('');

    useEffect(() => {
      if (formdata['Course_Pdf']) {
          setPdfName(formdata['Course_Pdf'].name || formdata['Course_Pdf']);
      } else {
          setPdfName('');
      }
  }, [formdata]);

 const handlePdfChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setPdfName(file.name);
        handlechange({ target: { name: 'Course_Pdf', value: file } });
    } else {
        setPdfName('');
        handlechange({ target: { name: 'Course_Pdf', value: null } });
    }
};

    return (
        <Dialog header="Course Form" visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} style={{ width: '50vw' }} 
            breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable
        >
            <form onSubmit={!formdata._id ? handlesave : handleupdate}>
                <div className="grid grid-cols-1 gap-y-2">
                    <div>
                        <div>
                            <label>Course Title</label>
                        </div>
                        <input type="text" name="Course_Title" value={formdata['Course_Title']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange}
                         required
                        />
                    </div>
                    <div>
                        <div>
                            <label>Course Name</label>
                        </div>
                        <select name="Course_Name" value={formdata['Course_Name']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
                        >
                            <option disabled selected>---Select a Course---</option>
                            <option>UPSC</option>
                            <option>TNPSC</option>
                            <option>IBPS</option>
                            <option>TET-TRB</option>
                            <option>SRB/DRB</option>
                            <option>PD & Soft Skills</option>
                        </select>
                    </div>
                    <div>
                    <div>
                        <label>Course Pdf</label>
                    </div>
                    <input type="file" name="Course_Pdf" className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlePdfChange} />
                    {pdfName && (
                        <div className="mt-2 text-sm text-gray-600">
                            Current PDF: {pdfName}
                        </div>
                    )}
                </div>
                    <div>
                        <div>
                            <label>Course Video</label>
                        </div>
                        <input type="text" name="Course_Video" value={formdata['Course_Video']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} 
                            required
                        />
                    </div>
                    <div>
                        <div>
                            <label>Status</label>
                        </div>
                        <select name="Status" value={formdata['Status']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required >
                            <option disabled selected>---Select a Status---</option>
                            <option>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                </div>

                <div className="flex justify-end gap-5 mt-5">
                    <button type="button" onClick={() => setVisible(false)}>
                        Cancel
                    </button>
                    <button color="primary" type="submit" className="px-4 py-2 text-white bg-secondary rounded-xl" >
                        <i className="fi fi-br-check"></i> {!formdata._id ? 'Save' : 'Update'}
                    </button>
                </div>
            </form>
        </Dialog>
    );
}
