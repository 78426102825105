import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import Studenttable from "../shared/components/Students/StudentTable";
import TableHeadpanel from "../shared/components/Students/TableHeadPanel";
import { apigetverifiedapplications, apisendcredentials, apiupdateaddedcourses } from "../shared/services/apiapplicationreceived/apireceivedapplications";
import useTable from "../hooks/useTable";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { apigetallclassforadd } from "../shared/services/courses/apicourses";

export default function StudentsPage() {
    const { page, first, rows, globalFilter, setGlobalFilter, onPage } = useTable();
    const [loadingEmail, setLoadingEmail] = useState(null);
    const [courses, setCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState({});
    const queryClient = useQueryClient();

    const { data } = useQuery({
        queryKey: ['studentdata', page, rows, globalFilter],
        queryFn: () => apigetverifiedapplications({ first, rows, globalFilter })
    });

    const { data: coursesData } = useQuery({
        queryKey: ['courses'],
        queryFn: () => apigetallclassforadd({})
    });

    const updateCoursesMutation = useMutation({
        mutationFn: ({ id, Added_Courses }) => apiupdateaddedcourses(id, { Added_Courses }),
        onSuccess: () => {
            queryClient.invalidateQueries(['studentdata']);
            toast.success('Courses updated successfully');
        },
        onError: (error) => {
            console.error('Error updating courses:', error);
            toast.error('Failed to update courses');
        }
    });

    useEffect(() => {
        if (coursesData) {
            setCourses(coursesData.resdata);
        }
    }, [coursesData]);

    useEffect(() => {
        if (data?.resdata) {
            const initialSelectedCourses = {};
            data.resdata.forEach((student, index) => {
                initialSelectedCourses[index] = student.Added_Courses || [];
            });
            setSelectedCourses(initialSelectedCourses);
        }
    }, [data?.resdata]);

    const sendEmail = async (Email, UserName, password, Mobile_Number) => {
        setLoadingEmail(Email);
        try {
            const res = await apisendcredentials({ Email, UserName, password, Mobile_Number });
            toast.success(res.message);
            setLoadingEmail(null);
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email');
        }
    };

    const handleCourseChange = (e, index) => {
        setSelectedCourses(prevState => ({...prevState, [index]: e.value}));
    };

    const handleSaveCourses = (index) => {
        const courses = selectedCourses[index] || [];
        if (data.resdata[index]?._id) {
            updateCoursesMutation.mutate({ id: data.resdata[index]._id, Added_Courses: courses });
        } else {
            console.error('Invalid ID:', data.resdata[index]?.id);
            toast.error('Invalid ID, cannot update courses');
        }
    };

    return (
        <>
            <TableHeadpanel setGlobalFilter={setGlobalFilter} />
            <Studenttable 
                data={data} 
                sendEmail={sendEmail} 
                loadingEmail={loadingEmail} 
                courses={courses} 
                handleCourseChange={handleCourseChange} 
                handleSaveCourses={handleSaveCourses} 
                selectedCourses={selectedCourses}
            />
            {data?.totallength > 0 && (
                <Cuspagination
                    page={page}
                    rows={rows}
                    onPage={onPage}
                    totallength={data?.totallength}
                />
            )}
        </>
    );
}