import { useCallback, useEffect, useState } from "react";
import useAuth from "../../shared/services/store/useAuth";
import { getAccountdetails, updateAccountdetails } from "../../shared/services/apicutomers/apicustomers";
import Cartsidebar from "../../shared/components/CheckoutandCart/Cartsidebar";
import { Tooltip } from "@nextui-org/react";
import useCartStore from "../../shared/services/store/usecart";

export default function MyAccount() {
  const [accountDetails, setAccountDetails] = useState(null); // Use null to indicate no data
  const [isEditing, setIsEditing] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {cart} = useCartStore();


  const [formData, setFormData] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Mobilenumber: ""
  });

  let isMounted = true;
  const { userdetails } = useAuth();

  const fetchShippingDetails = useCallback(async () => {
    try {
      const res = await getAccountdetails({ Email: userdetails?.Email });
      if (res.resdata?.length) {
        setAccountDetails(res.resdata[0]);
        setFormData({
          First_Name: res.resdata[0].First_Name,
          Last_Name: res.resdata[0].Last_Name,
          Email: res.resdata[0].Email,
          Mobilenumber: res.resdata[0].Mobilenumber
        });
      } else {
        setAccountDetails(null); // No data found
      }
    } catch (err) {
      console.log("Error fetching shipping details:", err);
    }
  }, [userdetails?.Email]);

  useEffect(() => {
    if (isMounted) {
      fetchShippingDetails();
    }
    return () => (isMounted = false);
  }, [fetchShippingDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = {
        _id: accountDetails._id,
        First_Name: formData.First_Name,
        Last_Name: formData.Last_Name,
        Mobilenumber: formData.Mobilenumber
      };

      const response = await updateAccountdetails(params);

      if (response.message) {
        console.log(response.message);
        await fetchShippingDetails();
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating account details:", error);
    }
  };

  useEffect(() => {
    const toTopButton = document.getElementById("to-top-button");

    const handleScroll = () => {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        toTopButton.classList.remove("hidden");
      } else {
        toTopButton.classList.add("hidden");
      }
    };

    const handleClick = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    if (toTopButton) {
      window.addEventListener("scroll", handleScroll);
      toTopButton.addEventListener("click", handleClick);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (toTopButton) {
        toTopButton.removeEventListener("click", handleClick);
      }
    };
  }, []);

  const toggleSidebar = () => { setIsSidebarOpen(!isSidebarOpen); };

  return (
    <>
    <div className="min-h-screen py-5 lg:py-20 bg-gray-50">
      <div className="max-w-3xl px-4 mx-auto">
        <div className="overflow-hidden bg-white shadow-lg rounded-xl">
          <div className="flex items-center justify-between px-6 py-6 border-b border-gray-200">
            <h1 className="text-2xl font-bold text-gray-800">My Account</h1>
            {accountDetails && (
              <button
                onClick={() => setIsEditing(!isEditing)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  isEditing
                    ? "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              >
                {isEditing ? "Cancel" : "Edit Profile"}
              </button>
            )}
          </div>

          <div className="p-6">
            {accountDetails ? (
              isEditing ? (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="First_Name"
                        value={formData.First_Name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="Last_Name"
                        value={formData.Last_Name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">Mobile Number</label>
                      <input
                        type="tel"
                        name="Mobilenumber"
                        value={formData.Mobilenumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4">
                    <button
                      type="submit"
                      className="px-6 py-2 text-white transition-colors bg-green-600 rounded-lg hover:bg-green-700"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              ) : (
                <div className="space-y-6">
                <div className="flex flex-col justify-between p-4 transition-colors duration-200 rounded-lg sm:flex-row sm:items-center bg-gray-50 hover:bg-gray-100">
                  <div>
                    <p className="text-sm text-gray-500">Full Name</p>
                    <p className="mt-1 font-medium text-gray-900">
                      {accountDetails?.First_Name} {accountDetails?.Last_Name}
                    </p>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full">
                      Personal Info
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-between p-4 transition-colors duration-200 rounded-lg sm:flex-row sm:items-center bg-gray-50 hover:bg-gray-100">
                  <div>
                    <p className="text-sm text-gray-500">Email Address</p>
                    <p className="mt-1 font-medium text-gray-900">{accountDetails?.Email}</p>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-green-800 bg-green-100 rounded-full">
                      Contact
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-between p-4 transition-colors duration-200 rounded-lg sm:flex-row sm:items-center bg-gray-50 hover:bg-gray-100">
                  <div>
                    <p className="text-sm text-gray-500">Mobile Number</p>
                    <p className="mt-1 font-medium text-gray-900">{accountDetails?.Mobilenumber}</p>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-purple-800 bg-purple-100 rounded-full">
                      Phone
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-between p-4 transition-colors duration-200 rounded-lg sm:flex-row sm:items-center bg-gray-50 hover:bg-gray-100">
                  <div>
                    <p className="text-sm text-gray-500">Account Status</p>
                    <div className="flex items-center mt-1">
                      <span className={`w-2 h-2 rounded-full ${
                        accountDetails?.Status === 'Active' ? 'bg-green-500' : 'bg-red-500'
                      } mr-2`}></span>
                      <p className="font-medium text-gray-900">{accountDetails?.Status}</p>
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-yellow-800 bg-yellow-100 rounded-full">
                      Status
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-between p-4 transition-colors duration-200 rounded-lg sm:flex-row sm:items-center bg-gray-50 hover:bg-gray-100">
                  <div>
                    <p className="text-sm text-gray-500">Account Type</p>
                    <p className="mt-1 font-medium text-gray-900">{accountDetails?.Role}</p>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-indigo-800 bg-indigo-100 rounded-full">
                      Role
                    </span>
                  </div>
                </div>
              </div>

              )
            ) : (
              <p className="text-center text-red-500">Account details not found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="fixed z-50 flex flex-col items-end space-y-8 bottom-24 right-5">
      <div className="relative" onClick={toggleSidebar}>
          <img src="/images/shopping-cart.png" alt="Cart" className="cursor-pointer w-14 h-14" />
          <span className="absolute flex items-center justify-center w-6 h-6 text-xs text-white bg-red-600 rounded-full -top-2 -right-2">
              {cart.length}
          </span>
      </div>

      <a href="https://wa.me/9791551876?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank" rel="noopener noreferrer">
          <Tooltip color="success" content="Contact on WhatsApp Now!" placement="left" showArrow={true}>
              <img src="/images/whatsapp.png" alt="WhatsApp" className="w-14 h-14 animate-bounce-up-down" />
          </Tooltip>
      </a>
  </div>
  <button
        id="to-top-button"
        title="Go To Top"
        className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
        </svg>
      </button>
  <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
    </>
  );
}
