import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";

/* eslint-disable react/prop-types */
export default function DataTable(props) {
    const { data, handleStatusChange,editform,confirm2 } = props;

    const handleDropdownChange = (id, event) => {
        const newStatus = event.target.value;
        handleStatusChange(id, newStatus);
    };

    return (
        <div className="overflow-auto bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5">
            <table className="min-w-full divide-y divide-gray-200 rounded-xl">
                <thead >
                    <tr>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.No.</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Full Name</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">DoB</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Gender</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Address</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Community</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Father Name</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Occupation</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Mobile Number</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Alternative Number</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Email Address</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Educational Qualification</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Name of the college</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">How you know</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Preferred Exam</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Application Status</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {data?.resdata?.map((item, index) => (
                        <tr key={index}>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Full_name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Dob}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Gender}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Address}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Community}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Father_Name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Occupation}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Mobile_Number}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Alternative_Number}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Email}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Educational_Qualification}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.College_Name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.how_you_know}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.preferred_exam}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                            <select 
                                value={item.Application_Status || "not verified"} 
                                onChange={(e) => handleDropdownChange(item._id, e)}
                                className="px-2 py-1 border rounded"
                            >
                                <option value="not verified">Not Verified</option>
                                <option value="verified">Verified</option>
                            </select>
                            </td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                                <Dropdown>
                                <DropdownTrigger>
                                    <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Dynamic Actions" >
                                    <DropdownItem onPress={() => editform(item)} color={"default"}>
                                    Edit
                                    </DropdownItem>
                                    <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                                    Delete
                                    </DropdownItem>
                                </DropdownMenu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
