import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import useCartStore from "../../services/store/usecart";
import Cartsidebar from "../CheckoutandCart/Cartsidebar";
import { Helmet, HelmetProvider } from "react-helmet-async";


export default function Aboutus() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const toTopButton = document.getElementById("to-top-button");
    
        const handleScroll = () => {
          if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            toTopButton.classList.remove("hidden");
          } else {
            toTopButton.classList.add("hidden");
          }
        };
    
        const handleClick = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        if (toTopButton) {
          window.addEventListener("scroll", handleScroll);
          toTopButton.addEventListener("click", handleClick);
        }
        return () => {
          window.removeEventListener("scroll", handleScroll);
          if (toTopButton) {
            toTopButton.removeEventListener("click", handleClick);
          }
        };
      }, []);

      const { cart } = useCartStore();

      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };
      
    return (
        <>
        <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Kavin&apos;s Academy - About Us</title>

          <meta name="keywords" content="Kavins Academy Salem,UPSC coaching Salem,TNPSC coaching Salem,Competitive exams coaching Tamil Nadu,IBPS coaching Salem,TET coaching Salem,TRB coaching Salem,DRB coaching Salem,Best UPSC classes Salem,Best TNPSC coaching Salem,Online coaching for UPSC TNPSC,Offline classes for competitive exams Salem,Competitive exam coaching Salem Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching Salem,Bank exams coaching Salem,Kavins Academy competitive exams,Salem Tamil Nadu coaching classes,UPSC classes Tamil Nadu,TNPSC coaching Tamil Nadu,Kavins Academy ,UPSC coaching center,TNPSC coaching ,Competitive exams coaching Tamil Nadu,IBPS coaching ,TET coaching Salem,TRB coaching ,DRB coaching ,Best UPSC classes Salem,Best TNPSC coaching ,Online coaching for UPSC TNPSC,Offline classes for competitive exams ,Competitive exam coaching  Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching ,Bank exams coaching ,Kavins Academy UPSC coaching
        TNPSC coaching center,Competitive exams coaching Tamil Nadu,IBPS coaching institute,TET coaching classes,TRB coaching for teachers,DRB coaching center,UPSC online coaching
        TNPSC online classes,Competitive exams preparation Tamil Nadu,Best coaching for UPSC TNPSC,Bank exams coaching institute,Government exams coaching center,Online and offline coaching for competitive exams,UPSC TNPSC IBPS coaching,Top competitive exams institute,UPSC preparation classes,Coaching classes for government exams,Tamil Nadu exam coaching,Kavins Academy exam preparation" />

          <meta name="description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
          We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

          <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/logo/favicon.png" />

          <meta property="og:title" content="Kavin's Academy - An Ultimate Destination fo UPSC, TNPSC, IBPS, TET-TRB, SRD/DRB (Coop) Aspirants" />

          <meta property="og:description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
          We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

          <meta property="og:url" content="https://www.kavinsacademy.com/aboutus" />
          <meta property="og:site" content="Kavin's Academy" />
          <meta property="og:site_name" content="Kavin's Academy" />
          <link rel="canonical" href="https://www.kavinsacademy.com/aboutus" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@kavinsacademy" />
          <meta name="twitter:title" content="Kavin's Academy | Distance Education in Tamil Nadu" />
          <meta name="twitter:description" content="With a dedicated team of experienced educators, we provide comprehensive study materials, personalized coaching, and result-oriented strategies to help students achieve their goals. " />
          <meta name="twitter:image" content="/images/logo/favicon.png" />

        </Helmet>
      </HelmetProvider>
            <section className="bg-[url('/images/about/bg-img.jpg')] bg-center bg-no-repeat overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-17 mx-auto">
                    <div className="grid grid-cols-1 gap-6 text-center md:gap-6">
                        <div>
                            <h1 className="mb-3 text-3xl font-extrabold text-white lg:text-5xl">About Us</h1>

                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[url('/images/about/bigger-bg.png')] bg-cover bg-no-repeat  overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-12 mx-auto">

                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-12 md:gap-6">
                        <div className="col-span-full lg:col-span-4">
                            <img className="w-full h-auto rounded-xl" src="/images/about/KA.png" alt="Image Description" />
                        </div>
                        <div className="flex flex-row items-center justify-center col-span-full lg:col-span-8">
                            <div>
                                <div className="text-start">
                                    <h1 className="mb-3 text-3xl font-bold text-gray-900 lg:text-5xl">About Kavin&apos;s</h1>
                                </div>
                                <p className="mb-3 text-lg text-justify text-gray-500 lg:text-xl">With cutting-edge facilities and a group of talented, experienced faculty members working to craft
                                    UPSC and TNPSC aspirants towards a career in the most sought-after Central and State Civil
                                    Services, we hope to offer world-class education to Salem.</p>
                                <p className="mb-3 text-lg text-gray-500 lg:text-xl">Our goal is to offer rigorous classroom instruction on par with that of prestigious educational
                                    institutions</p>
                                <p className="mb-3 text-lg text-justify text-gray-500 lg:text-xl">We concentrate on transferring knowledge and developing the attitudes required for an
                                    exemplary civil servant.</p>
                            </div>
                        </div>
                        <div className="mt-10 col-span-full lg:col-span-4">
                            <figure className="relative">
                                <figcaption className="flex items-center space-x-2">
                                    <span className="relative  inline-flex items-center justify-center h-[2.875rem] w-[2.875rem] rounded-full bg-regal-yellow hover:bg-regal-blue">
                                        <span className="text-lg font-medium leading-none text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="currentColor" viewBox="0 0 512 512"><path d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>
                                        </span>
                                    </span>
                                    <div className="flex-auto">
                                        <div className="text-3xl font-semibold text-slate-900 ">
                                            <a href="#!" tabIndex="0">
                                                <span className="absolute inset-0"></span>Our Vision</a>
                                        </div>
                                    </div>
                                </figcaption>
                                <blockquote className="mt-6 text-slate-700 ">
                                    <p className="text-lg text-justify text-gray-500 lg:text-xl">Our Vision is to be the aspirants’ choice by acting as a launching pad to young aspirants who are
                                        effective, proactive, professional, forward-thinking, transparent, altruistic, and passionate about
                                        serving the country by inculcating self reflection and critical thinking.</p>
                                </blockquote>
                            </figure>
                        </div>
                        <div className="mt-10 col-span-full lg:col-span-8">
                            <figure className="relative">
                                <figcaption className="flex items-center space-x-2">
                                    <span className="relative  inline-flex items-center justify-center h-[2.875rem] w-[2.875rem] rounded-full bg-regal-yellow hover:bg-regal-blue">
                                        <span className="text-lg font-medium leading-none text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="currentColor" viewBox="0 0 512 512"><path d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" /></svg>
                                        </span>
                                    </span>
                                    <div className="flex-auto">
                                        <div className="text-3xl font-semibold text-slate-900 ">
                                            <a href="#!" tabIndex="0">
                                                <span className="absolute inset-0"></span>Our Mission</a>
                                        </div>
                                    </div>
                                </figcaption>
                                <blockquote className="mt-6 text-slate-700">
                                    <p className="text-lg text-justify text-gray-500 lg:text-xl">In order to help young people succeed in these competitive exams, we mentor them on what to
                                        study, unlearn, and relearn. We also aim to help them approach COMPETITIVE EXAMS in the appropriate way.As a learning organisation, we strive to develop as a team while navigating the highs and lows of
                                        the preparation process.In order to approach competitive exams prudently, the learning method and viewpoint in every
                                        subject are rewired with the assistance of the expert faculty in the relevant domains In addition, we also want to combine cost-effectiveness and high quality standards.</p>
                                </blockquote>
                            </figure>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 mt-10 lg:grid-cols-12 md:gap-6">
                        <div className="order-1 mt-10 col-span-full lg:col-span-4">
                            <figure className="relative flex justify-center">
                                <img className="relative left-0 z-10 h-auto border border-gray-100 shadow-sm lg:left-20 w-60 rounded-xl" src="/images/about/Chairman.jpg" alt="Image Description" />
                                <div className="relative top-0 right-10 -z-10 w-[10rem] lg:w-[25rem] h-auto rounded-xl  bg-regal-blue rotate-12" alt="Image Description"></div>
                            </figure>
                            <blockquote className="flex justify-center mt-2 text-slate-700">
                                <div className="mb-3">
                                    <p className="font-bold text-center"> ‘Nallasiriyar’</p>
                                    <p className="font-bold text-center">Mr. K SHANMUGAM <span className="text-xs font-normal">M.A.,M.Ed.,M.Phil.,</span></p>
                                </div>
                            </blockquote>
                        </div>
                        <div className="order-2 mt-10 col-span-full lg:col-span-8">
                            <figure className="relative">
                                <figcaption className="flex items-end justify-end border-b-2 border-regal-yellow">

                                    <div className="text-3xl font-semibold text-slate-900 ">
                                        <a href="#!" tabIndex="0">
                                            <span className="absolute inset-0"></span>Chairman</a>
                                    </div>
                                </figcaption>
                                <blockquote className="mt-2 text-slate-700">
                                    <p className="mb-3 text-lg text-justify text-gray-500 lg:text-xl">
                                        An Headmaster (Retired) who is still active in the field of education. Started his career in a private school in a rural His contribution towards the upliftment enormous. He possesses a Masters in English Literature and Education. He had assisted a lot of students in choosing and excelling in their career.

                                        He made the schools shine and the students excel in every aspect wherever Government of Tamilnadu honoured him with the Dr. Radhakrishnan award in of rural students is he had worked in the year 2019. Acts as an advisor to few  educational institutions.
                                    </p>
                                    <p className="text-lg italic text-justify text-regal-yellow lg:text-xl text-bold">Teaches — `Reading Comprehension`</p>
                                </blockquote>
                            </figure>
                        </div>
                        <div className="order-4 mt-10 col-span-full lg:col-span-8 lg:order-3">
                            <figure className="relative">
                                <figcaption className="flex items-start justify-start border-b-2 border-regal-yellow">

                                    <div className="text-3xl font-semibold text-slate-900 ">
                                        <a href="#!" tabIndex="0">
                                            <span className="absolute inset-0"></span>
                                            Director</a>
                                    </div>
                                </figcaption>
                                <blockquote className="mt-2 text-slate-700">
                                    <p className="mb-3 text-lg text-justify text-gray-500 lg:text-xl">
                                        A passionate and dynamic teacher, boasts an impressive educational background with bachelor degrees in Engineering, English, Education and master&apos;s in History. He also has a Diploma in Labour law with Administrative law. His exceptional dedication to academia is evident in his distinction of passing the UGC NET JRF.Having accumulated a wealth of experience, he has guided countless civil service aspirants in esteemed institutions. His voracious reading habits have instilled him with a profound understanding of the challenges faced by aspiring civil servants, making his classes engaging, captivating, and remarkably insightful.
                                    </p>
                                    <p className="text-lg italic text-justify text-regal-yellow lg:text-xl text-bold">Teaches — ‘History and Internal Security’</p>
                                </blockquote>
                            </figure>
                        </div>
                        <div className="order-3 mt-10 col-span-full lg:col-span-4 lg:order-4">
                            <figure className="relative flex justify-center">

                                <img className="relative left-0 z-10 h-auto border border-gray-100 shadow-sm lg:left-20 w-60 rounded-xl" src="/images/about/Director.jpg" alt="Image Description" />
                                <div className="relative top-0 right-10 -z-10 w-[10rem] lg:w-[25rem] h-auto rounded-xl  bg-regal-blue rotate-12" alt="Image Description"></div>
                            </figure>
                            <blockquote className="flex justify-center mt-2 text-slate-700">
                                <div className="mb-3">
                                    <p className="font-bold text-center"> Asst. Professor</p>
                                    <p className="font-bold text-center">Mr.S S VAANJHI KAVIN VIGNESH <span className="text-xs font-normal" />
                                        B.E.,M.A,B.Ed.,Dip.LLAL.,<span className="border-t border-gray-500">Ph.D.,</span>
                                    </p>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <div className="relative z-50 cart" onClick={toggleSidebar}>
                <img src="/images/shopping-cart.png" alt="" className="fixed mx-2 mt-4 cursor-pointer bottom-44 right-5 w-14 h-14" />
                <span className="fixed flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full bottom-52 right-5">
                {cart.length}
                </span>
            </div>
            <a href="https://wa.me/9791551876?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank">
                <button>
                    <Tooltip color="success" content="Contact on Whatsapp Now!" placement="left-start" showArrow={true}>
                        <img src="/images/whatsapp.png" alt="" className="fixed z-50 bottom-24 right-5 w-14 h-14 animate-bounce-up-down" />
                    </Tooltip>
                </button>
            </a>
            <button id="to-top-button" title="Go To Top"
                className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                </svg>
            </button>

        <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

        </>
    )
}