/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import apiurl from "../../services/apiendpoint/apiendpoint";
import { useEffect } from "react";

export default function AddandEditform(props) {
    const { visible, setVisible, formdata, handlechange, handlesave, handleupdate } = props;

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        handlechange({ target: { name: 'Book_image', value: files }});
    };
    const calculateSalePrice = (regularPrice, discount) => {
        const price = parseFloat(regularPrice) || 0;
        const discountRate = parseFloat(discount) || 0;
        return Math.round(price - (price * (discountRate / 100)));
    };
    useEffect(() => {
        if (formdata.Regular_Price && formdata.Discount) {
            const salePrice = calculateSalePrice(formdata.Regular_Price, formdata.Discount);
            handlechange({ target: { name: 'Sale_Price', value: salePrice }});
        }
    }, [formdata.Regular_Price, formdata.Discount]);

    return (
      <Dialog header="Course Form" visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "641px": "100vw" }} maximizable >
        <form onSubmit={!formdata._id ? handlesave : handleupdate}>
          <div className="grid grid-cols-1 gap-y-2">
            <div>
              <div> <label>Book Name</label> </div>
              <input type="text" name="Book_Name" value={formdata["Book_Name"]} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required />
            </div>
            <div>
              <div> <label>Book Cover Images</label> </div>
              <div className="mb-2">
                {formdata.Book_image && formdata.Book_image.length > 0 && (
                  <div className="text-sm font-medium">Uploaded Images:</div>
                )}
                <div className="grid grid-cols-6 gap-2 mt-2">
                  {formdata.Book_image &&
                    formdata.Book_image.map((image, index) => {
                      if (image instanceof File) {
                        return (
                          <img key={index} src={URL.createObjectURL(image)} alt={`Book Cover ${index + 1}`} className="object-cover rounded w-28 h-28" />
                        );
                      } else {
                        return (
                          <img key={index} src={`${apiurl()}/${image}`} alt={`Book Cover ${index + 1}`} className="object-cover rounded w-28 h-28" />
                        );
                      }
                    })}
                </div>
              </div>
              <input type="file" multiple name="Book_image" className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handleFileChange} />
            </div>
            <div>
              <div>
                <label>Quantity</label>
              </div>
              <input type="text" name="Quantity" value={formdata["Quantity"]} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required />
            </div>
            <div>
              <div>
                <label>Regular Price</label>
              </div>
              <input type="text" name="Regular_Price" value={formdata["Regular_Price"]} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
              />
            </div>
            <div>
              <div>
                <label>Discount</label>
              </div>
              <input type="text" name="Discount" value={formdata["Discount"]} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} required
              />
            </div>
            <div>
              <div>
                <label>Sale Price</label>
              </div>
              <input type="text" name="Sale_Price" value={formdata["Sale_Price"]} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" readOnly
              />
            </div>
            <div>
              <div>
                <label>Status</label>
              </div>
              <select name="Status" className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} value={formdata.Status} required >
                <option value="">---Select a Status---</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div>
              <div>
                <label>Stock</label>
              </div>
              <select name="Stock" className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" onChange={handlechange} value={formdata.Stock} required >
                <option value="">---Select a Stock Status---</option>
                <option value="Active">Stock</option>
                <option value="Inactive">Out of Stock</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end gap-5 mt-5">
            <button type="button" onClick={() => setVisible(false)}>
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 text-white bg-secondary rounded-xl" >
              <i className="fi fi-br-check"></i>{" "}
              {!formdata._id ? "Save" : "Update"}
            </button>
          </div>
        </form>
      </Dialog>
    );
}
