import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import useCartStore from "../../services/store/usecart";
import Cartsidebar from "../CheckoutandCart/Cartsidebar";
import { Helmet, HelmetProvider } from "react-helmet-async";


export default function Ourgallery() {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);


    useEffect(() => {
        const toTopButton = document.getElementById("to-top-button");
    
        const handleScroll = () => {
          if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            toTopButton.classList.remove("hidden");
          } else {
            toTopButton.classList.add("hidden");
          }
        };
    
        const handleClick = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        if (toTopButton) {
          window.addEventListener("scroll", handleScroll);
          toTopButton.addEventListener("click", handleClick);
        }
        return () => {
          window.removeEventListener("scroll", handleScroll);
          if (toTopButton) {
            toTopButton.removeEventListener("click", handleClick);
          }
        };
      }, []);

      const { cart } = useCartStore();

      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };

    return (
        <>
        <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Kavin&apos;s Academy - Gallery</title>

          <meta name="keywords" content="Kavins Academy Salem,UPSC coaching Salem,TNPSC coaching Salem,Competitive exams coaching Tamil Nadu,IBPS coaching Salem,TET coaching Salem,TRB coaching Salem,DRB coaching Salem,Best UPSC classes Salem,Best TNPSC coaching Salem,Online coaching for UPSC TNPSC,Offline classes for competitive exams Salem,Competitive exam coaching Salem Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching Salem,Bank exams coaching Salem,Kavins Academy competitive exams,Salem Tamil Nadu coaching classes,UPSC classes Tamil Nadu,TNPSC coaching Tamil Nadu,Kavins Academy ,UPSC coaching center,TNPSC coaching ,Competitive exams coaching Tamil Nadu,IBPS coaching ,TET coaching Salem,TRB coaching ,DRB coaching ,Best UPSC classes Salem,Best TNPSC coaching ,Online coaching for UPSC TNPSC,Offline classes for competitive exams ,Competitive exam coaching  Tamil Nadu,UPSC TNPSC IBPS coaching Kavins Academy,Government exams coaching ,Bank exams coaching ,Kavins Academy UPSC coaching
        TNPSC coaching center,Competitive exams coaching Tamil Nadu,IBPS coaching institute,TET coaching classes,TRB coaching for teachers,DRB coaching center,UPSC online coaching
        TNPSC online classes,Competitive exams preparation Tamil Nadu,Best coaching for UPSC TNPSC,Bank exams coaching institute,Government exams coaching center,Online and offline coaching for competitive exams,UPSC TNPSC IBPS coaching,Top competitive exams institute,UPSC preparation classes,Coaching classes for government exams,Tamil Nadu exam coaching,Kavins Academy exam preparation" />

          <meta name="description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
          We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

          <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/logo/favicon.png" />

          <meta property="og:title" content="Kavin's Academy - An Ultimate Destination fo UPSC, TNPSC, IBPS, TET-TRB, SRD/DRB (Coop) Aspirants" />

          <meta property="og:description" content="Kavins Academy is a premier coaching institute specializing in competitive exam preparation for aspirants across Tamil Nadu. 
          We offer both online and offline classes tailored for exams such as UPSC, TNPSC, IBPS, TET-TRB, and SRD/DRB (Cooperative)." />

          <meta property="og:url" content="https://www.kavinsacademy.com/gallery" />
          <meta property="og:site" content="Kavin's Academy" />
          <meta property="og:site_name" content="Kavin's Academy" />
          <link rel="canonical" href="https://www.kavinsacademy.com/gallery" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@kavinsacademy" />
          <meta name="twitter:title" content="Kavin's Academy | Distance Education in Tamil Nadu" />
          <meta name="twitter:description" content="With a dedicated team of experienced educators, we provide comprehensive study materials, personalized coaching, and result-oriented strategies to help students achieve their goals. " />
          <meta name="twitter:image" content="/images/logo/favicon.png" />

        </Helmet>
      </HelmetProvider>
            <section className="bg-[url('/images/about/bg-img.jpg')] bg-center bg-no-repeat overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-17 mx-auto">
                    <div className="grid grid-cols-1 gap-6 text-center md:gap-6">
                        <div>
                            <h1 className="mb-3 text-3xl font-extrabold text-white lg:text-5xl">Our Gallery</h1>

                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-12 mx-auto">
                    <div className="grid grid-cols-1 gap-6 md:gap-6">
                        <h1 className="mb-5 text-2xl font-extrabold">Inauguration</h1>
                    </div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (1).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (2).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (3).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (4).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (5).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (6).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (7).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (8).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (9).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (10).jpg" alt="" />
                        </div>

                    </div>

                </div>
            </section>
            <div className="relative z-50 cart" onClick={toggleSidebar}>
                <img src="/images/shopping-cart.png" alt="" className="fixed mx-2 mt-4 cursor-pointer bottom-44 right-5 w-14 h-14" />
                <span className="fixed flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full bottom-52 right-5">
                {cart.length}
                </span>
            </div>
            <a href="https://wa.me/9791551876?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank">
                <button>
                    <Tooltip color="success" content="Contact on Whatsapp Now!" placement="left-start" showArrow={true}>
                        <img src="/images/whatsapp.png" alt="" className="fixed z-50 bottom-24 right-5 w-14 h-14 animate-bounce-up-down" />
                    </Tooltip>
                </button>
            </a>
            <button id="to-top-button" title="Go To Top"
                className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                </svg>
            </button>

        <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

        </>
    )
}