import toast from "react-hot-toast";
import useForm from "../hooks/useForm";
import useTable from "../hooks/useTable";
import AddandEditform from "../shared/components/AddProducts/Addandeditform";
import CustomTable from "../shared/components/AddProducts/CustomTable";
import TableheadPanel from "../shared/components/AddProducts/TableheadPanel";
import { apideletebooks, apigetallbooks, apisavebooks, apiupdatebooks } from "../shared/services/apisavebooks/apisavebooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import Cuspagination from "../shared/components/Courses/Cuspagination";


export default function AddProductspage(initialValues) {

    const { setGlobalFilter,page, first, rows, globalFilter,onPage } = useTable();
    const { formdata, handlechange, visible, setVisible, openform,resetForm,editform } = useForm(initialValues);
    const queryClient = useQueryClient();

    const handlesave = async (e) => {
        e.preventDefault();
        const res = await apisavebooks(formdata);
        console.log(res);
        if (res.message === "Successfully saved") {
            handlesuccess(res);
        } else {
            toast.error(res.message);
        }
    };

    const { data } = useQuery({
      queryKey: ['bookdata', page, rows, globalFilter],
      queryFn: () => apigetallbooks({ first, rows, globalFilter }),
      keepPreviousData: true
    });
  

      const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('bookdata');
        resetForm();
    }

      const confirm2 = (_id) => {
        confirmDialog({
          message: 'Do you want to delete this record?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          defaultFocus: 'reject',
          acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
          rejectClassName: 'ml-2  py-2 px-3',
          accept: () => { handledelete(_id) }
        });
      };
      const handledelete = async (_id) => {
        const res = await apideletebooks(_id);
        res.message == "Book Successfully deleted" ? handlesuccess(res) : toast.error(res.message);
      }

      const handleupdate = async (e) => {
        e.preventDefault();
        const { _id, ...Othersdata } = formdata
        const res = await apiupdatebooks(_id, Othersdata);
        res.message == "Successfully updated" ? handlesuccess(res) : toast.error(res.message);
    
      }


    return (
        <>
            <TableheadPanel openform={openform} setGlobalFilter={setGlobalFilter} />
            <CustomTable data={data} confirm2={confirm2} editform={editform}/>
            <AddandEditform visible={visible} setVisible={setVisible} formdata={formdata}
                handlechange={handlechange} handlesave={handlesave} handleupdate={handleupdate}/>
                  {data?.totallength > 0 && (<Cuspagination page={page} rows={rows} onPage={onPage} totallength={data?.totallength} />)}
            <ConfirmDialog />
        </>
    )
}