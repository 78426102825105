/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";

export default function Addandeditform(props) {
    const { visible, setVisible, formdata, handlechange, handlesave, handleupdate } = props;
    return (
        <Dialog header="Course Form" visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable >
            <form onSubmit={!formdata._id ? handlesave : handleupdate}>
                <div className="grid grid-cols-1 gap-y-2">
                    <div>
                        <div>
                            <label>Your Photo</label>
                        </div>
                        <input type="file" name="ProfileImage"  className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} />
                    </div>
                    <div>
                        <div>
                            <label>Full Name</label>
                        </div>
                        <input type="text" name="Full_name" value={formdata['Full_name']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Date of Birth</label>
                        </div>
                        <input type="date" name="Dob" value={formdata['Dob']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Gender</label>
                        </div>
                        <select
                            name="Gender"
                            value={formdata['Gender']}
                            className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange}
                            required
                        >
                            <option disabled selected>---Select a gender---</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Trans.G</option>
                            <option>Diff. Abled</option>
                        </select>
                    </div>
                    <div>
                        <div>
                            <label>Address</label>
                        </div>
                        <input type="text" name="Address" value={formdata['Address']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Community</label>
                        </div>
                        <input type="text" name="Community" value={formdata['Community']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Father Name</label>
                        </div>
                        <input type="text" name="Father_Name" value={formdata['Father_Name']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Occupation</label>
                        </div>
                        <input type="text" name="Occupation" value={formdata['Occupation']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Mobile Number</label>
                        </div>
                        <input type="text" name="Mobile_Number" value={formdata['Mobile_Number']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Alternative Number</label>
                        </div>
                        <input type="text" name="Alternative_Number" value={formdata['Alternative_Number']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Email Address</label>
                        </div>
                        <input type="text" name="Email" value={formdata['Email']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Educational Qualification</label>
                        </div>
                        <input type="text" name="Educational_Qualification" value={formdata['Educational_Qualification']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>Name of the college</label>
                        </div>
                        <input type="text" name="College_Name" value={formdata['College_Name']} className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange} required />
                    </div>
                    <div>
                        <div>
                            <label>How did you come to know about Kavin&apos;s Academy?</label>
                        </div>
                        <select
                            name="how_you_know"
                            value={formdata['how_you_know']}
                            className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange}
                            required
                        >
                            <option disabled selected>---Select a option---</option>
                            <option>Online</option>
                            <option>WhatsApp</option>
                            <option>Poster</option>
                            <option>Newspaper</option>
                            <option>Friends / Relatives</option>
                        </select>
                    </div>
                    <div>
                        <div>
                            <label>Exam(s) preferred to start preparation?</label>
                        </div>
                        <select
                            name="preferred_exam"
                            value={formdata['preferred_exam']}
                            className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                            onChange={handlechange}
                            required
                        >
                            <option disabled selected>---Select a option---</option>
                            <option>UPSC</option>
                            <option>TNPSC</option>
                            <option>IBPS</option>
                            <option>TET-TRB</option>
                            <option>SRB/DRB (Coop)</option>
                            <option>PD & Soft Skills</option>
                            <option>Others</option>
                        </select>
                    </div>

                </div>

                <div className="flex justify-end gap-5 mt-5">
                    <button type="button" onClick={() => setVisible(false)}>
                        Cancel
                    </button>
                    <button
                        color="primary"
                        type="submit"
                        className="px-4 py-2 text-white bg-secondary rounded-xl"
                    >
                        <i className="fi fi-br-check"></i> {!formdata._id ? 'Save' : 'Update'}
                    </button>
                </div>
            </form>
        </Dialog>
    )
}