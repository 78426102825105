import { useCallback, useEffect, useState } from "react";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { apigetallclassesdesign } from "../../services/apionandupcomeclasses/apigetclasses";

export default function Coursesoffered() {
    const [ongoingClasses, setOngoingClasses] = useState([]);
    const [upcomingClasses, setUpcomingClasses] = useState([]);
    let isMounted = true;

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        
        const formattedMonth = monthNames[date.getMonth()];
        const formattedDay = date.getDate().toString().padStart(2, '0');
        
        return `${formattedMonth} ${formattedDay}`;
      };


    const fetchClasses = useCallback(async()=>{
        try {
            const data = await apigetallclassesdesign();
            const ongoing = data.resdata.filter(resdata => resdata.ClassType === 'Ongoing');
            const upcoming = data.resdata.filter(resdata => resdata.ClassType === 'Upcoming');

            setOngoingClasses(ongoing);
            setUpcomingClasses(upcoming);
        } catch (error) {
            console.error("Error fetching classes:", error);
        }
    },[])

    useEffect(() => {
        if (isMounted) {
            fetchClasses();
        }
        return () => (isMounted = false);
      }, [fetchClasses]);

    useEffect(() => {
        if (ongoingClasses.length > 1 || upcomingClasses.length > 1) {
            new Swiper(".mySwiper", {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                },
            });
        }
    }, [ongoingClasses, upcomingClasses]);

    return (
        <>
            {/*========================================================= ongoing classes =========================================================*/}
            <section id="ongoingcourses">
                <div className="max-w-[70rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* Title */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Ongoing Classes</h2>

                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 text-center lg:grid-cols-2 md:gap-6">
                        <div className="w-full h-full swiper mySwiper col-span-full">
                            <div className="swiper-wrapper ">
                                {ongoingClasses.map((course, index) => (
                                    <div key={index} className="swiper-slide ">
                                        <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                            <div>
                                                <div className="px-6 py-6 bg-yellow-500">
                                                    <h1>{course.ClassType}</h1>
                                                </div>
                                                <div className="p-2 text-white bg-gray-800">
                                                    {course.Year}
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <h1 className="text-xl font-semibold">{course.Couse_Title}</h1>
                                                <h1 className="mb-3 text-lg">{course.Program_Name}</h1>
                                                <p className="text-sm"><div dangerouslySetInnerHTML={{ __html: course.Description }} /></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="upcomingcourses" className=" bg-slate-50">
                <div className="max-w-[70rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Upcoming Classes</h2>

                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 text-center lg:grid-cols-2 md:gap-6">
                        <div className="w-full h-full swiper mySwiper col-span-full">
                            <div className="swiper-wrapper ">
                                {upcomingClasses.map((course, index) => (
                                    <div key={index} className="swiper-slide ">
                                        <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                            <div>
                                                <div className="px-6 py-6 bg-yellow-500">
                                                    <h1>{formatDate(course.Date)}</h1>
                                                </div>
                                                <div className="p-2 text-white bg-gray-800">
                                                    {course.Year}
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <h1 className="text-xl font-semibold">{course.Couse_Title}</h1>
                                                <h1 className="mb-3 text-lg">{course.Program_Name}</h1>
                                                <p className="text-sm"><div dangerouslySetInnerHTML={{ __html: course.Description }} /></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
