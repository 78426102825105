export default function Refundpolicy() {

    return (
        <section>
            <div className="max-w-[65rem] mx-auto py-5 p-5">
                <div className="flex items-start justify-start">
                    <h1 className="text-2xl font-bold text-regal-blue">Refund and Cancellation Policy</h1>
                </div>
                <div className="my-5">
                    <p className="text-lg text-justify">At Kavins Academy, we want you to be satisfied with your purchase. Please review our Refund and Cancellation 
                        Policy to understand your options.
                    </p>

                    <h1 className="my-2 text-xl font-semibold">1. Order Cancellations</h1>
                    <p className="my-3 text-lg text-justify">Personal Information: When you make a purchase, register an account, or contact us, we may collect your name,
                        email address, phone number, and shipping address.
                    </p>
                    <p className="my-3 text-lg text-justify">Before Shipment: You may cancel your order before it has been shipped by contacting us at kavins.enquiry@gmail.com or calling us at 9047127777, 9791551876.
                    </p>
                    <p className="my-3 text-lg text-justify">After Shipment: Once the order has been shipped, it cannot be canceled. However, you may return the product according to our Return Policy.
                    </p>

                    <h1 className="text-xl font-semibold">2. Returns</h1>
                    <p className="my-3 text-lg text-justify">Eligibility: You may return any book in its original condition within 7 days of receipt for a full refund, excluding shipping charges. To initiate a return, please contact us at kavins.enquiry@gmail.com with your order details.</p>
                    <p className="my-3 text-lg text-justify">Shipping Costs: Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>

                    <h1 className="text-xl font-semibold">3. Refunds</h1>
                    <p className="my-3 text-lg text-justify">Processing Time: Once we receive your return, we will inspect the item and notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will be applied to your original payment method within 7-10 business days.
                    </p>
                    <p className="my-3 text-lg text-justify">Legal Requirements: We may disclose your information if required by law or to protect our rights and property.</p>

                    <h1 className="text-xl font-semibold">4. Damaged or Defective Items</h1>
                    <p className="my-3 text-lg text-justify">If you receive a damaged or defective item, please contact us immediately at kavins.enquiry@gmail.com. We will arrange for a replacement or refund at no additional cost to you.
                    </p>

                  
                    <h1 className="text-xl font-semibold">5. Contact Us</h1>
                    <p className="my-3 text-lg text-justify">If you have any questions about our Refund and Cancellation Policy, please contact us at:</p>
                    <ol className="space-y-1">
                        <li>Email :kavins.enquiry@gmail.com</li>
                        <li>Mobile :9047127777, 9791551876</li>
                        <li>Address :No. 22 / C / 8, Shiva plaza, First floor, Opp. to Sarada College (Near Pillar 49), Fairlands, Salem - 636016</li>
                    </ol>
                </div>
            </div>
        </section>
    )
}