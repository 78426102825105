/* eslint-disable react/prop-types */
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import apiurl from '../../services/apiendpoint/apiendpoint';
import axios from 'axios';
import { gettoken } from '../../../../shared/services/token/token';

export default function Pdfview({ pdfvisible, setpdfvisible, pdfdata }) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {
            zoomPlugin: true,
            pageNavigationPlugin: true,
        },
        renderToolbar: (Toolbar) => { }
    });

    const [pdfUrl, setPdfUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const disableTextSelection = () => {
            document.addEventListener('selectstart', (e) => e.preventDefault());
        };

        const disableRightClick = () => {
            document.addEventListener('contextmenu', (e) => e.preventDefault());
        };

        const disableCopyPaste = (e) => {
            if (e.ctrlKey && (e.key === 'c' || e.key === 'p')) {
                e.preventDefault();
            }
        };

        disableTextSelection();
        disableRightClick();
        document.addEventListener('keydown', disableCopyPaste);

        return () => {
            document.removeEventListener('selectstart', disableTextSelection);
            document.removeEventListener('contextmenu', disableRightClick);
            document.removeEventListener('keydown', disableCopyPaste);
        };
    }, []);

    useEffect(() => {
        const fetchPdfWithToken = async () => {
            try {
                setLoading(true);
                setError(null);

                const token = gettoken(); 
                const response = await axios.get(`${apiurl()}/stream/apigetcoursepdf?_id=${pdfdata._id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: 'blob', 
                });

                const pdfBlobUrl = URL.createObjectURL(response.data);
                setPdfUrl(pdfBlobUrl);
            } catch (error) {
                setError('Failed to load PDF. Please try again.');
                console.error('Error fetching PDF:', error);
            } finally {
                setLoading(false);
            }
        };

        if (pdfdata && pdfdata._id) {
            fetchPdfWithToken();
        }
    }, [pdfdata]);

    return (
        <div>
            <Dialog visible={pdfvisible} onHide={() => { setpdfvisible(false) }} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    {loading ? (
                        'Loading...'
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    )}
                </Worker>
            </Dialog>
        </div>
    );
}
